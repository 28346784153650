<template>
  <div class="main-container opening-hours-header-img">
    <div
      class="header-main-img"
      v-if="showHeader == 1"
    >
      <img :src="headerImage" alt="header-img" class="img-fluid header-img">
    </div>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    name: 'Header',

    data () {
      return {
        images: {
          common: 'heaer_bg.jpg',
          event: 'event-banner.png'
        },
        selectedImage: 'common'
      }
    },
    computed: {
      ...mapGetters({ cartItemCount: 'getCartItemCount', eventConfiguration: 'getEventConfiguration', sandwichConfiguration: 'getSandwichConfiguration', snackConfiguration: 'getSnackConfiguration', showHeader: 'getShowHeader' }),

      headerImage() {
        if (this.$route.path.includes('event')) {
          return this.eventConfiguration.bannerUrl || `/banners/${this.images[this.selectedImage]}`
        } else if (this.$route.path.includes('cart') || this.$route.path.includes('order-success')) {
          const productType = localStorage.getItem('product_type')

          switch (productType) {
            case 'snack':
              return this.snackConfiguration.bannerUrl || `/banners/${this.images[this.selectedImage]}`
            case 'sandwich':
              return this.sandwichConfiguration.bannerUrl || `/banners/${this.images[this.selectedImage]}`
            default:
              return `/banners/${this.images[this.selectedImage]}`
          }
        } else {
          return `/banners/${this.images[this.selectedImage]}`
        }
      }
    },
    watch: {
      '$route.name': {
        handler (val) {
          if (['Events', 'EventRegister'].includes(val)) {
            this.selectedImage = 'event'
          }
        },
        immediate: true,
        deep: true
      }
    }
  }
</script>
